.DayPicker {
  font-size: 1.6rem;
}

.DayPicker *:focus {
  outline: 0;
}

.DayPicker-Caption {
  color: #424242;
}

.DayPicker-Day {
  color: #424242;
}

/* 
   Put a circle around the current day for emphasis. 
   Note: "Outside" days don't display a number for the day, so we shouldn't
   display the circle in that case. Otherwise, there would be a circle with
   nothing in it.
*/
.DayPicker-Day.DayPicker-Day--today:not(.DayPicker-Day--outside) {
  font-weight: 700;
  border-radius: 50%;
  /* Needed for the border-radius to take effect. Default display is table-cell which doesn't allow border to be rounded */
  display: block;
  border-width: 1px;
  border-style: solid;
}

.DayPicker-Day.DayPicker-Day--today:not(.DayPicker-Day--selected):not(
    .DayPicker-Day--disabled
  ) {
  color: #043a54;
  border-color: #043a54;
}

.DayPicker-Day.DayPicker-Day--today.DayPicker-Day--disabled {
  border-color: #c3c3c3;
}

.DayPicker-Day.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
    .DayPicker-Day--outside
  ) {
  background-color: #58920c;
  /* Helps improve readability */
  color: white;
}

.DayPicker-Day.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
    .DayPicker-Day--outside
  ):hover {
  background-color: #58920c;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(
    .DayPicker-Day--selected
  ):not(.DayPicker-Day--outside):hover {
  background-color: #f1f3f7;
}

.DayPicker-Day.DayPicker-Day--disabled {
  color: #c3c3c3;
  cursor: default;
}

/* Hide outside days when they can't be clicked */
.DayPicker-Day.DayPicker-Day--outside[aria-disabled="true"] {
  visibility: hidden;
}

.DayPicker-Weekday {
  color: #515151;
}

/*
 Make the month navigations bigger and increase the space between them.
 This makes them easier to interact with on mobile.
*/
.DayPicker-NavButton {
  top: 1em;
  width: 1em;
  height: 1em;
}
.DayPicker-NavButton--prev {
  margin-right: 1.75em;
  background-image: url("images/datepicker_arrow_prev.svg");
}
.DayPicker-NavButton--next {
  background-image: url("images/datepicker_arrow_next.svg");
}

/*
  A disabled month arrow should be visible, but its appearance should
  clearly indicate that it's disabled.
*/
.DayPicker-NavButton--interactionDisabled,
.DayPicker-NavButton--interactionDisabled:hover,
.DayPicker-NavButton--interactionDisabled:focus {
  display: block;
  opacity: 0.3;
  cursor: default;
}
